/*--------------------------------------------------------------
15. Testimonial
----------------------------------------------------------------*/

.cs_testimonial.cs_style_4 {
  background-color: #fff;
  padding: 50px 30px;
  box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
  padding: 40px 20px;
  .cs_testimonial_meta {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .cs_testimonial_avatar {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    overflow: hidden;
    flex: none;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .cs_testimonial_meta {
    margin-bottom: 32px;
  }
  .cs_rating {
    margin-top: 32px;
  }
}
.cs_testimonial_carousel_2 {
  .cs_slider_activate {
    width: 58vw;
    @media (max-width: 991px) {
      width: 90vw;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  .cs_testimonial.cs_style_4 {
    max-width: 390px;
    @media (max-width: 420px) {
      max-width: 300px;
    }
  }
  .slick-list {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: -30px;
    margin-bottom: -30px;
    box-shadow: -10px 0px 30px -20px rgba(48, 123, 196, 0.1);
    border-radius: 60px;
  }
  .cs_slider_navigation.cs_style_1 {
    justify-content: flex-end;
    margin-top: 0;
    margin-top: 40px; // Add space between arrows and heading
    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }
}
