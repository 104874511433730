/*--------------------------------------------------------------
8. Footer
----------------------------------------------------------------*/


.cs_newsletter.cs_style_4 {
  .cs_newsletter_form {
    position: relative;
  }

  .cs_btn.cs_style_1 {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    @media (max-width: 1700px) {
      right: 5px;
    }
  }

  .cs_form_field {
    padding-right: 180px;

    @media (max-width: 1700px) {
      padding-right: 165px;
    }

    @media (max-width: 575px) {
      padding-right: 135px;
      padding-left: 15px;
    }

    @media (max-width: 350px) {
      padding-right: 120px;
    }
  }

  @media (max-width: 1700px) {
    .cs_form_field {
      border-radius: 30px;
    }
  }

  @media (max-width: 575px) {
    .cs_btn.cs_style_1 {
      padding: 12px 25px;
    }
  }

  @media (max-width: 350px) {
    .cs_btn.cs_style_1 {
      padding: 12px 18px;
    }
  }
}

.cs_newsletter.cs_style_4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-bottom: 1px solid #fff;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 35px;
  }

  @media (max-width: 380px) {
    br {
      display: none;
    }
  }

  .cs_newsletter_right {
    max-width: 500px;
    width: 100%;
  }

  .cs_form_field {
    border-color: rgba(255, 255, 255, 0.5);
    color: #000;

    &:focus {
      border-color: rgba(255, 255, 255, 0.8);
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    &::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .cs_btn.cs_style_1 {
    color: #fff;

    &:before {
      background: $accent;
    }

    &:after {
      background: $accent;
    }

    img {
      filter: initial;
    }
  }

  .cs_newsletter_title {
    font-size: 42px;
    margin-bottom: 0;

    @media (max-width: 1199px) {
      font-size: 36px;
    }
  }

  .cs_newsletter_left {
    display: flex;
    align-items: center;
    gap: 35px;

    @media (max-width: 1400px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}

.cs_social_links_wrap {
  display: flex;
  align-items: center;
  gap: 20px 30px;

  h2 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
}

.cs_social_links {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  a {
    height: 40px;
    width: 40px;
    flex: none;
    display: flex;
    align-items: center;
    border-radius: 50%;
    background-color: rgba($white, 0.5);
    color: #000;
    font-size: 22px;
    justify-content: center;

    &:hover {
      transform: scale(1.2);
      background-color: $accent;
    }
  }
}

.cs_footer.cs_style_2 {
  .cs_footer_in {
    display: flex;
    justify-content: space-between;
    gap: 40px 30px;
    padding: 40px 0 50px;

    @media (max-width: 991px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .cs_footer_bottom {
    text-align: center;
    border-top: 1px solid rgba($white, 0.2);
    padding: 30px 0;
  }

  .cs_social_links_wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .cs_contact_widget i {
    background-color: $accent;
    color: #000;
  }

  .cs_text_widget {
    img+p {
      margin-top: 50px;
    }

    +.cs_contact_widget {
      margin-top: 20px;
    }
  }

  .cs_menu_widget {
    +.cs_social_links_wrap {
      margin-top: 80px;
    }
  }

  .cs_menu_widget {
    a {
      &:hover {
        color: $accent;
      }
    }
  }
}

.cs_footer_bottom {
  position: relative;
  z-index: 2;
}

.cs_text_widget {
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 500;

  p {
    margin: 0;
  }
}

.cs_contact_widget {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }

  i {
    height: 20px;
    width: 20px;
    display: flex;
    flex: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    margin-right: 19px;
    margin-top: 5px;
  }
}

.cs_menu_widget {
  li {
    &:not(:last-child) {
      margin-bottom: 17px;
    }
  }
}

.cs_footer.cs_style_2.cs_type_1 {
  .cs_footer_bottom {
    border: none;
    padding: 16px 0;
    background: #feb208;
  }

  .cs_copyright {
    color: $primary;
  }
}

.cs_footer.cs_style_2 {
  @media (max-width: 991px) {
    .cs_footer_in {
      padding: 40px 0;

      .cs_footer_col {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }

    .cs_footer_item {
      text-align: left;
      margin-bottom: 30px;
    }

    .cs_text_widget {
      font-size: 16px;

      p {
        margin-top: 20px !important;
      }
    }

    .cs_menu_widget+.cs_social_links_wrap {
      margin-top: 30px;
    }
  }
}