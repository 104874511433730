/*--------------------------------------------------------------
20. About
----------------------------------------------------------------*/
.cs_about.cs_style_1 {
  .cs_about_img {
    position: relative;
    @media (min-width: 992px) {
      padding-right: 70px;
    }
  }
  .cs_about_text_list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      padding-left: 60px;
      &:not(:last-child) {
        margin-bottom: 50px;
      }
      > i {
        position: absolute;
        left: 0;
        top: 5px;
        @media (max-width: 991px) {
          top: 2px;
        }
      }
      > h3 {
        margin-bottom: 25px;
      }
    }
  }

    .cs_about.cs_style_1 {
      padding-top: 60px;
      /* Add top padding to About section */
  
      @media (max-width: 1700px) {
        padding-top: 40px;
      }
  
      @media (max-width: 991px) {
        padding-top: 30px;
      }
    }
    
  .cs_about_mini_img {
    position: absolute;
    top: -75px;
    right: 20%;
  }
  .cs_right_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cs_rotate_animation {
    animation: rotate360 15s linear infinite;
  }
  &.cs_type_1 {
    .cs_about_mini_img {
      right: initial;
      top: 44%;
      left: 35%;
    }
    br {
      @media (max-width: 1400px) {
        display: none;
      }
    }
  }
  &.cs_type_2 {
    padding-left: 102px;
    @media (max-width: 1700px) {
      padding-left: 50px;
    }
    @media (max-width: 1400px) {
      padding-left: 0px;
    }
    .cs_about_mini_img {
      top: 50%;
      right: initial;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .cs_about_img {
      padding-right: 0;
      position: relative;
    }
  }
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cs_about.cs_style_1 {
  @media (max-width: 991px) {
    .cs_about_img {
      margin-bottom: 60px;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .cs_section_heading {
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }
}


.cs_about.cs_style_1 {
  .cs_about_mini_img {
    img.cs_rotate_animation {
      width: 180px; // Base size
      height: 180px;

      @media (max-width: 991px) {
        width: 120px;
        height: 120px;
      }

      @media (max-width: 575px) {
        width: 150px;
        height: 150px;
      }
    }

    .cs_right_icon {
      width: 60px;
      height: 60px;

      @media (max-width: 991px) {
        width: 50px;
        height: 50px;
      }
    }
  }
}