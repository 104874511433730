.cs_site_header.cs_style1.cs_active_sticky {
  background-color: #fff;
}
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky {
  background-color: #010406;
}
.cs_accent_bg .cs_social_links_wrap h2,
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky .cs_toolbox {
  color: #fff;
}
.cs_contact_widget i {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  color: #fff;
  padding: 4px;
}

.cs_hero.cs_style_1 {
  .cs_text_btn_2,
  .cs_hero_title,
  .cs_hero_subtitle {
    position: relative;
    z-index: 1;
  }

  .cs_hero_text {
    position: initial;
    z-index: initial;
  }
}
.cs_accordian_body_wrap {
  overflow: hidden;
  transition: all 0.4s ease;
}
.cs_tabs.cs_style1 .cs_tab_links .cs_tab_link_in {
  cursor: pointer;
}
.cs_rating {
  i {
    display: flex;
  }
}
.cs_shape_wrap .cs_shape_2 {
  top: -3%;
}


.form-check .form-check-input {
  border-color: #636363;
}

.cs_error {
  padding: 80px 0;
  min-height: 100vh;
}
.cs_nav .cs_nav_list .cs_munu_dropdown_toggle.active + ul {
  display: block;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}



/* New Responsive Fixes - Keep in _custom.scss */
.cs_site_header.cs_style1 {
  .cs_main_header_in {
    @media (min-width: 992px) {
      .cs_nav {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        .cs_nav_list {
          flex-wrap: nowrap;
          white-space: nowrap;

          .cs_nav_button_item {
            margin-left: auto;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .cs_main_header_in {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;

      .cs_main_header_left {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .cs_menu_toggle {
        position: relative;
        top: auto;
        right: auto;
        margin: 0;
        order: 2;
      }

      .cs_site_branding {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        order: 1;
      }
    }
  }
}

// Keep basic utilities and fixes
html,
body {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-width: 320px;
  overflow-x: hidden;
}

.cs_hero_wrap,
.cs_main_header,
.cs_footer {
  max-width: 100vw;
  overflow-x: hidden;
}