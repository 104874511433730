/*--------------------------------------------------------------
14. Banner
----------------------------------------------------------------*/

.cs_banner.cs_style_3,
.cs_banner.cs_style_5 {
  padding: 225px 0 155px;
  min-height: 700px;
  position: relative;
  @media (max-width: 1700px) {
    min-height: 600px;
  }
  @media (max-width: 1199px) {
    min-height: 500px;
    padding: 160px 0 125px;
    .container {
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    padding: 155px 0 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
      max-width: 720px;
    }
  }
  @media (max-width: 767px) {
    .container {
      max-width: 540px;
    }
  }

  .cs_banner_title {
    margin-bottom: 25px;
    @media (max-width: 1199px) {
      font-size: 50px;
    }
    @media (max-width: 575px) {
      font-size: 48px;
    }
  }
  .cs_banner_img {
    position: absolute;
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 110px;
    img {
      max-height: 100%;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  .cs_banner_text {
    position: relative;
    z-index: 1;
    width: 50%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  @media (max-width: 1400px) {
    .cs_fs_20 {
      font-size: 18px;
    }
  }
  @media (max-width: 991px) {
    .cs_fs_20 {
      font-size: 16px;
    }
  }
  @media (max-width: 575px) {
    br {
      display: none;
    }
  }
}
.cs_banner.cs_style_3 {
  .cs_banner_img {
    left: 0;
    width: 50%;
    padding-left: 6%;
    padding-right: 12px;
    @media (max-width: 1700px) {
      padding-left: 12px;
    }
  }
  .cs_banner_subtitle {
    max-width: 550px;
    @media (max-width: 991px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 991px) {
    .cs_banner_text {
      margin-left: auto;
    }
  }
}

