/*--------------------------------------------------------------
## Color Variable
----------------------------------------------------------------*/
$white: #ffffff;
$black: #000;
$primary: #000;
$secondary: rgba($primary, 0.52);
$ternary: #b7b7b7;
$border: #eaeaea;
$gray: #fafafa;
$accent: #feb208;