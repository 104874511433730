/*--------------------------------------------------------------
5. Slider
----------------------------------------------------------------*/
.cs_gap_20 {
  .slick-slide {
    padding-left: 10px;
    padding-right: 10px;
  }
  .slick-list {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.cs_slider_navigation.cs_style_1 {
  display: flex;
  gap: 25px;
  @media (max-width: 1700px) {
    gap: 15px;
  }
  .cs_slider_prev,
  .cs_slider_next {
    width: 90px;
    height: 70px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    @media (max-width: 1700px) {
      width: 80px;
      height: 60px;
    }
    img {
      transition: inherit;
    }
    &:hover {
      background-color: #feb208;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  @media (max-width: 575px) {
    margin-top: 40px;
  }
}

