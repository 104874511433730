$primary: #ffb000;
$secondary: #b80000;
$red: #b80000;
$black: #0c331e;
$white: #fcfcf5;
$background: #fcfcf5;
$buttonBackground: #ffcf9d;
$disabled: #859e90;
$disabledBlack: #afafaf;
$disabledButtonBackground: #fff6ec;

:export {
  primary: $primary;
  secondary: $red;
  red: $red;
  black: $black;
  white: $white;
  background: $background;
  buttonBackground: $buttonBackground;
  disabled: $disabled;
}

@font-face {
  font-family: "Poppins";
  src: url('https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap');
}

.old-form-container {
  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 130px 50px;

  font-size: 25px;
  margin: 0;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }


  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;


  .MuiRating-root .MuiRating-iconFilled {
    color: $buttonBackground;
  }

  .MuiRating-root .MuiRating-iconEmpty {
    border-color: $buttonBackground;
  }

  .MuiButton-root {
    background-color: $primary !important;
    border-radius: 25px !important;
    padding: 10px 25px !important;
    text-transform: none !important;
    color: $red !important;
    font-size: 1.0em !important;
    transition: background-color 0.3s ease !important;
    font-weight: 550 !important;

    &:hover {
      background-color: $red !important;
      color: $primary !important;
    }
  }

  .MuiButton-root.Mui-disabled {
    color: $disabledBlack !important;
    background-color: $disabledButtonBackground !important;
  }

  .MuiStepLabel-iconContainer {
    display: none !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 20px !important;
    border: solid 2px;
    border-color: $secondary !important;
    background-color: $background;
    width: 450px !important;
    height: 70px;
    padding: 5px 20px 5px 5px !important;
    font-size: 25px !important;
    font-weight: 550 !important;
  }

  .MuiInputBase-input {
    color: $black !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
  }

  .MuiInputBase-root::before {
    border: 0px !important;
  }

  .MuiInputBase-root::after {
    border: 0px !important;
  }

  .date {
    .MuiSvgIcon-root {
      color: $secondary !important;
      font-size: 25px !important;
    }

    .MuiButtonBase-root {
      display: none;
    }
  }

  .MuiInput-root {
    border: solid 2px !important;
    border-color: $secondary !important;
    border-radius: 20px !important;
    background-color: $background;
    height: 70px;
    width: 450px !important;
    padding: 20px;
    font-size: 25px !important;
    font-weight: 550 !important;
  }

  .primary {
    color: $primary;
  }

  .secondary {
    color: $secondary;
  }

  .red {
    color: $red;
  }

  .black {
    color: $black;
  }

  .white {
    color: $white;
  }

  .background {
    color: $background;
  }

  .disabled {
    color: $disabled;
  }

  .button-background {
    color: $buttonBackground;
  }

  .bold {
    font-weight: 600 !important;
  }

  .semi-bold {
    font-weight: 600 !important;
  }

  .medium {
    font-weight: medium !important;
  }

  .logo-size {
    width: 220px;
    height: 100px;
  }

  .fade-in {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }

  .fade-in.active {
    opacity: 1;
  }

  @media (max-width: 1885px) {

    font-size: 26px;

  }

  @media (max-width: 1740px) {

    font-size: 24px;

  }

  @media (max-width: 1550px) {

    font-size: 23px;

  }

  @media (max-width: 1400px) {

    font-size: 22px;

  }

  @media (max-width: 720px) {
    .logo-size {
      width: 150px;
      height: 50px;
    }

    display: flex !important;
    font-size: 16px !important;
    flex-direction: column !important;
    ;
    justify-content: center !important;
    ;
    align-items: center !important;
    ;

    .MuiOutlinedInput-root {
      width: 250px !important;
      height: 50px;
      padding: 3px 15px 3px 3px !important;
      font-size: 20px !important;
      font-weight: 550 !important;
    }

    .MuiInput-root {
      width: 250px !important;
      height: 50px;
      padding: 15px;
      font-size: 15px !important;
      font-weight: 550 !important;
    }

    .bold {
      font-weight: 600 !important;
    }
  }

  @media (max-width: 410px) {
    display: flex;
    font-size: 16px;

    .bold {
      font-weight: 600 !important;
    }
  }
}

.MuiInput-root,
.MuiOutlinedInput-root {
  border: 2px solid $primary !important;

  &:hover,
  &:focus {
    border-color: $primary !important;
  }
}