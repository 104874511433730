/*--------------------------------------------------------------
4. General
----------------------------------------------------------------*/


.cs_fs_72 {
  font-size: 72px;
  line-height: 1.14em;
  @media (max-width: 1700px) {
    font-size: 56px;
  }
  @media (max-width: 991px) {
    font-size: 50px;
  }
  @media (max-width: 575px) {
    font-size: 48px;
  }
  @media (max-width: 350px) {
    font-size: 42px;
  }
}

.cs_fs_32 {
  font-size: 32px;
  line-height: 1.31em;
  @media (max-width: 1700px) {
    font-size: 26px;
  }
  @media (max-width: 991px) {
    font-size: 24px;
  }
  @media (max-width: 350px) {
    font-size: 21px;
  }
}
.cs_fs_24 {
  font-size: 24px;
  line-height: 1.41em;
  @media (max-width: 1700px) {
    font-size: 22px;
  }
}
.cs_fs_20 {
  font-size: 20px;
  line-height: 1.5em;
  @media (max-width: 991px) {
    font-size: 18px;
  }
}
.cs_fs_18 {
  font-size: 18px;
  line-height: 1.5em;
}

.cs_img_filed {
  height: 100%;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.cs_light {
  font-weight: 300;
}

.cs_normal {
  font-weight: 400;
}

.cs_medium {
  font-weight: 500;
}

.cs_semibold {
  font-weight: 600;
}

.cs_bold {
  font-weight: 700;
}

.cs_extra_bold {
  font-weight: 800;
}

.cs_black {
  font-weight: 900;
}

.cs_radius_3 {
  border-radius: 3px;
}

.cs_radius_5 {
  border-radius: 5px;
}

.cs_radius_7 {
  border-radius: 7px;
}

.cs_radius_10 {
  border-radius: 10px;
}

.cs_radius_15 {
  border-radius: 15px;
}
.cs_radius_20 {
  border-radius: 20px;
}
.cs_radius_25 {
  border-radius: 25px;
}
.cs_radius_30 {
  border-radius: 30px;
}

.cs_mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cs_m0 {
  margin: 0;
}

hr {
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid #bcbcbc;
  opacity: 1;
}

.cs_vertical_middle {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.cs_vertical_middle_in {
  flex: none;
  width: 100%;
}

.cs_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs_bg_filed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.cs_heading_color {
  color: $primary;
}
.cs_body_color {
  color: $secondary;
}
.cs_accent_color {
  color: $accent;
}

.cs_cholo_color {
  color : #333333;
}
.cs_section_spacing {
  height: 80px;
  /* Adjust this value to increase/decrease spacing */
  width: 100%;

  @media (max-width: 1700px) {
    height: 60px;
  }

  @media (max-width: 991px) {
    height: 40px;
  }
}

.cs_nady_color {
  color: #feb208;
}
.cs_white_color {
  color: #fff;
}

.cs_black_color {
  color: #000;
} 
.cs_blackk_color {
  color: #333333;
}

.cs_white_bg {
  background-color: $white;
}
.cs_accent_bg {
  background-color: #feb208;
}
.cs_mamamia_bg {
  background-color: #f3d1b6;
}
.cs_gray_bg_1 {
  background-color: rgba(28, 163, 190, 0.2);
}


@media (min-width: 1701px) {
  .container {
    max-width: 1650px;
  }
}

.cs_shadow_1 {
  box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
}
.cs_shadow_2 {
  box-shadow: 6px 4px 70px 8px rgba(48, 123, 196, 0.09);
}
.cs_btn.cs_style_1 {
  border-radius: 24px;
  box-shadow: 0px 4px 20px 0px rgba(39, 71, 96, 0.17);
  font-weight: 500;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 40px;
  outline: none;
  border: none;
  position: relative;
  background: transparent;
  @media (max-width: 991px) {
    padding: 12px 30px;
  }
  span {
    position: relative;
    z-index: 2;
  }
  i {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 15px;
    height: 11px;
    z-index: 2;
    transition: all 0.4s ease-in-out;
  }
  img {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    filter: brightness(0) invert(1);
    &:first-child {
      left: -20px;
    }
    &:last-child {
      left: 0;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    transition: all 0.4s ease-in-out;
  }
  &::before {
    background-color: #feb208;
  }
  &::after {
    background-image: green;
    opacity: 0;
  }
  &:hover {
    background-color: green;
    img {
      &:first-child {
        left: 0px;
      }
      &:last-child {
        left: 20px;
      }
    }
  }
}

.cs_input_label {
  margin-bottom: 12px;
  @media (max-width: 1700px) {
    margin-bottom: 10px;
  }
}
.cs_form_field,

.cs_form_field_2 {
  width: 100%;
  display: block;
  border: 1px solid #636363;
  outline: none;
  border-radius: 6px;
  padding: 13px 20px;
  transition: all 0.3s ease;
  &:focus {
    border-color: $accent;
  }
}
textarea.cs_form_field {
  resize: none;
}
textarea::placeholder,
input::placeholder,
.cs_form_field::placeholder,
.cs_form_field_2::placeholder {
  color: $secondary;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder,
.cs_form_field:-ms-input-placeholder,
.cs_form_field_2:-ms-input-placeholder {
  color: $secondary;
}

textarea::-ms-input-placeholder,
input::-ms-input-placeholder,
.cs_form_field::-ms-input-placeholder,
.cs_form_field_2::-ms-input-placeholder {
  color: $secondary;
}
.cs_with_icon_input {
  position: relative;
  i {
    position: absolute;
    top: 30px;
    right: 30px;
    color: $accent;
    font-size: 20px;
    pointer-events: none;
    display: flex;
    @media (max-width: 1700px) {
      top: 20px;
      left: 20px;
    }
  }
}

.cs_tab {
  display: none;
  &.active {
    display: block;
  }
}



.cs_rating {
  width: 99px;
  position: relative;
  font-size: 16px;
  .cs_rating_bg {
    display: flex;
    gap: 2px;
  }
  .cs_rating_percentage {
    display: flex;
    gap: 2px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
  }
}

.cs_radio_wrap {
  position: relative;
  .cs_radio_input {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    &:checked {
      + .cs_radio_label {
        &::after {
          transform: scale(1);
        }
      }
    }
  }
  .cs_radio_label {
    display: block;
    padding-left: 32px;
    &::before {
      content: '';
      height: 20px;
      width: 20px;
      border: 1px solid $accent;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 0;
    }
    &::after {
      content: '';
      height: 14px;
      width: 14px;
      background-color: $accent;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: 3px;
      transition: all 0.3s ease;
      transform: scale(0);
    }
  }
}
.cs_radio_group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 42px;
  @media (max-width: 1700px) {
    gap: 10px 30px;
  }
}
.cs_shape_wrap {
  position: relative;
  .cs_shape_1 {
    width: 1884px;
    height: 1884px;
    border-radius: 50%;
    opacity: 0.6;
    background: radial-gradient(
      42.35% 42.35% at 50% 50%,
      rgba(241, 209, 134, 0.38) 0%,
      rgba(134, 187, 241, 0) 100%
    );
    position: absolute;
    top: 31%;
    right: -37%;
    z-index: -1;
    &.cs_position_1 {
      right: initial;
      left: -38%;
      top: -59%;
    }
    &.cs_position_2 {
      right: 38%;
      top: 39%;
    }
    &.cs_position_3 {
      right: 42%;
      top: 22%;
    }
    &.cs_position_4 {
      right: -35%;
      top: initial;
      bottom: -10%;
    }
    &.cs_position_5 {
      right: -30%;
      top: -8%;
    }
  }
  .cs_shape_2 {
    position: absolute;
    left: 52%;
    top: 13%;
    z-index: -1;
  }
  .cs_shape_3 {
    position: absolute;
    right: 0;
    top: -41%;
    z-index: -1;
  }
}


.cs_search_form {
  max-width: 360px;
  width: 100%;
  position: relative;
  input {
    width: 100%;
    border: 1px solid $accent;
    height: 50px;
    border-radius: 20px;
    padding: 5px 20px;
    outline: none;
  }
  .cs_search_btn {
    position: absolute;
  }
  .cs_search_btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #274760;
    &:hover {
      color: $accent;
    }
  }
}



.cs_contact_info.cs_style_1 {
  li {
    &:not(:last-child) {
      margin-bottom: 42px;
      @media (max-width: 1700px) {
        margin-bottom: 25px;
      }
      @media (max-width: 991px) {
        margin-bottom: 25px;
      }
    }
  }
}




@for $i from 1 through 13 {
  .cs_hour_#{$i} {
    height: (148 * $i) + px !important;
  }
}



.cs_map {
  height: 650px;
  @media (max-width: 1700px) {
    height: 550px;
  }
  @media (max-width: 1400px) {
    height: 450px;
  }
  @media (max-width: 575px) {
    height: 350px;
  }
  iframe {
    height: 100%;
    width: 100%;
    border: none;
    filter: grayscale(100%);
    border-radius: 30px;
  }
}


.form-check {
  padding-left: 38px;
  margin-bottom: 0;
  color: $primary;
  font-size: 18px;
  line-height: 1.5em;
  .form-check-input {
    margin-left: -38px;
    width: 24px;
    height: 24px;
    margin-top: 2px;
    &:checked {
      background-color: $accent;
      border-color: $accent;
    }
  }
}




.cs_newsletter.cs_style_5 {
  text-align: center;
  .cs_newsletter_title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.5em;
    margin-bottom: 30px;
    @media (max-width: 1700px) {
      font-size: 24px;
    }
  }
  .cs_form_field {
    border-color: #636363;
    font-size: 20px;
    line-height: 1.5em;
    padding: 19px 25px;
    border-radius: 7px;
    margin-bottom: 28px;
    @media (max-width: 1700px) {
      font-size: 18px;
      padding: 13px 20px;
      margin-bottom: 20px;
    }
  }
}

/* Start Comment */


.url {
  font-weight: 600;
  font-size: 20px;
  color: $primary;
  display: inline-block;
  line-height: 1.5em;
  @media (max-width: 1700px) {
    font-size: 18px;
  }
}


/* End Comment */

/* Start Time Picker */



/* End Time Picker */
/* Start Date Picker */

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: none;
  color: $primary;
  background: transparent;
  text-align: center;
}
.ui-widget {
  font-family: inherit;
}




.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-state-highlight {
  color: $accent;
  font-weight: 500;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  color: $accent;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background-color: rgba($accent, 0.15);
  color: $accent;
  border: 1px solid rgba($accent, 0.3);
  border-radius: 7px;
  font-weight: 500;
}

/* End Date Picker */




@keyframes semi-rotate-anim {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(25px) rotate(-53deg);
  }
}

@keyframes up-down-anim {
  40% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(40px) rotate(-1deg);
  }
}

@keyframes swing-anim {
  65% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(-48px) rotate(-75deg);
  }
}
@keyframes zoom-anim {
  50% {
    transform: scale(0.7);
    // opacity: 0.5;
  }
}
/* End New Animations */



.cs_section_heading.cs_style_1 {
  @media (max-width: 991px) {
    .cs_section_title {
      br {
        display: none;
      }
    }
  }
}
.cs_section_wrap_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 50px;
  @media (max-width: 991px) {
    .cs_avatar_card {
      margin-bottom: -60px;
    }
  }
}
.cs_avatar_card {
  .cs_avatar_card_title {
    margin-bottom: 24px;
  }
}
.cs_avatar_group {
  display: flex;
  padding-left: 30px;
  .cs_avatar_item {
    flex: none;
    height: 106px;
    width: 106px;
    border-radius: 50%;
    padding: 5px;
    margin-left: -30px;
    background-color: #fff;
    @media (max-width: 1400px) {
      height: 80px;
      width: 80px;
      padding: 4px;
    }
    @media (max-width: 991px) {
      height: 60px;
      width: 60px;
      padding: 1px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @media (max-width: 420px) {
      display: none;
      &:nth-child(2),
      &:first-child,
      &:last-child {
        display: flex;
      }
    }
  }
}

.container {
  @media (min-width: 992px) {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  @media (max-width: 1199px) {
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
}

html {
  font-size: 16px;

  @media (max-width: 1199px) {
    font-size: 15px;
  }

  @media (max-width: 991px) {
    font-size: 14px;
  }
}