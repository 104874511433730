/*--------------------------------------------------------------
11. Hero
----------------------------------------------------------------*/

.cs_hero.cs_style_1 {
  position: relative;
  min-height: 100vh; // Instead of fixed height
  overflow: visible;
  padding-bottom: 100px;
  position: relative;

  @media (max-width: 1700px) {
    height: 1075px;
  }

  @media (max-width: 1480px) {
    height: 1115px;
  }

  @media (max-width: 1380px) {
    height: 980px;
  }

  @media (max-width: 1300px) {
    height: 1010px;
  }

  @media (max-width: 991px) {
    height: initial;
  }

  .cs_hero_text {
    position: relative;
    z-index: 1;

    @media (max-width: 1700px) {
      max-width: 60%;
      ;
    }

    @media (max-width: 1100px) {
      max-width: 100%;
      ;
    }
  }

  .cs_hero_wrap {
    height: 100%;
    width: 100%;
    padding-top: 248px;
    position: relative;

    @media (max-width: 1300px) {
      padding-top: 220px;
    }

    @media (max-width: 1199px) {
      padding-top: 150px;
    }

    @media (max-width: 575px) {
      padding-top: 180px; // adjust this value to add more space
    }

    >.container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

    }
  }

  .cs_hero_title {
    color: #333333;
    margin-bottom: 29px;
    max-width: 900px;

    @media (max-width: 1800px) {
      max-width: 710px;
      font-size: 46px;
    }

    @media (max-width: 1199px) {
      max-width: 580px;
    }

    @media (max-width: 1199px) {
      margin-bottom: 20px;
    }

    @media (max-width: 575px) {
      font-size: 25px !important; // Decrease from 42px to something smaller
      margin-bottom: 10px; // Adjust spacing as needed
    }
  }

  .cs_hero_subtitle {

    max-width: 700px;
    margin-bottom: 50px;

    @media (max-width: 1700px) {
      max-width: 600px;
    }

    @media (max-width: 1199px) {
      max-width: 580px;
    }

    @media (max-width: 991px) {
      margin-bottom: 35px;
    }

    @media (max-width: 575px) {
      font-size: 11px !important; // Decrease from 42px to something smaller
      margin-bottom: 20px; // Adjust spacing as needed
    }
  }

  /* New styles for the savings text */
  .cs_hero_savings {
    color: #cc0000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    display: block;

    @media (max-width: 991px) {
      font-size: 20px;
      margin-bottom: 10px;
    }

    @media (max-width: 575px) {
      font-size: 18px !important; // Decrease from 42px to something smaller
      margin-bottom: 10px; // Adjust spacing as needed
      margin-top: 10px !important;
    }
  }

  .cs_disclaimer_text {
    font-size: 10px;
    line-height: 1.4;
    color: #666;
    font-family: inherit;
    max-width: 480px;
    margin: 0;

    @media (max-width: 991px) {
      font-size: 7px;
      text-align: center;
      max-width: 100%;
    }

    @media (max-width: 575px) {
      font-size: 6px;
      margin-bottom: 10px;
    }
  }

  .cs_hero_info_wrap {
    padding: 70px 140px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    transform: translateY(0);
    position: relative;
    z-index: 2;

    @media (max-width: 1700px) {
      padding: 70px;
    }

    @media (max-width: 1199px) {
      padding: 70px 25px;
      gap: 24px;
    }

    @media (max-width: 991px) {
      justify-content: center;
    }

    @media (max-width: 767px) {
      justify-content: space-between;
    }

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 40px 25px;
    }
  }

  .cs_hero_info_title {
    font-size: 16px;
    line-height: 1.6em;
    margin-bottom: 4px;
  }

  .cs_hero_info_subtitle {
    line-height: 1.3em;
    margin: 0;
  }

  .cs_hero_info_icon {
    height: 70px;
    width: 70px;
    padding: 18px;

    @media (max-width: 1199px) {
      height: 60px;
      width: 60px;
      padding: 15px;
    }

    img {
      filter: brightness(0) invert(1);
    }
  }

  .cs_hero_info {
    gap: 24px;

    @media (max-width: 1199px) {
      gap: 15px;
    }
  }

  .cs_hero_img {
    position: absolute;
    bottom: -10px;
    right: 6%;
    max-height: 86%;

    @media (max-width: 1700px) {
      right: 4%;
      max-height: 84%;
    }

    @media (max-width: 1480px) {
      right: 1%;
    }

    @media (max-width: 1300px) {
      max-height: 82%;
      right: 0;
    }

    @media (max-width: 1199px) {
      max-height: 72%;
    }

    @media (max-width: 991px) {
      max-height: 100%;
      position: initial;
      margin-bottom: -100px;
      margin-top: 20px;
    }

    @media (max-width: 575px) {
      margin-bottom: -55px;
    }
  }

  .cs_hero_patents {
    position: absolute;
    left: 39.3%;
    top: 58.5%;

    @media (max-width: 1700px) {
      left: 33%;
    }
  }
}


.cs_hero.cs_style_1 {
  margin-bottom: 40px;
  /* Add bottom margin to Hero section */

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}


.cs_hero_btn_wrap {
  position: relative;
  display: inline-block;
  margin-top: 20px;

  @media (max-width: 55700px) {
    margin-bottom: 220px;
  }

  @media (max-width: 2700px) {
    margin-bottom: 120px;
  }

  @media (max-width: 1700px) {
    margin-bottom: 70px;
  }

  @media (max-width: 1199px) {
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }

  @media (max-width: 575px) {}

  /* Added to contain the button and icon */
  /* This is necessary for absolute positioning of the icon */
}

.cs_obtenir_tarif_btn {

  background-color: #feb208;
  /* Orange background */
  color: #cc0000;
  /* White text */
  border: none;
  border-radius: 8px;
  /* Rounded corners */
  padding: 25px 40px;
  /* Comfortable padding */
  font-size: 30px;
  /* Adjust as needed for readability */
  font-family: Poppins;
  /* Use default font */
  cursor: pointer;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s ease;
}

.cs_click_icon_wrapper {
  position: absolute;
  right: 40px; // Adjust this to align with "tarif"
  bottom: -40px; // Adjust this to control how far below the button
}


.cs_click_icon {
  color: #cc0000;
  transform-origin: center;
  transition: all 0.3s ease;
}

.cs_obtenir_tarif_btn:hover~.cs_click_icon_wrapper .cs_click_icon {
  color: #feb208;
  transform: scale(1.1);
}

/* Increase the size to be 2.5-3x larger than the current button */
// .cs_obtenir_tarif_btn {
//   transform: scale(1);
// }

/* Optional: Adjust scaling based on exact size comparison */
@media (max-width: 768px) {
  .cs_obtenir_tarif_btn {
    transform: scale(1.1);
    font-size: 24px !important; // Reduce button text size if needed
    padding: 20px 30px; // Adjust button padding if desired
    /* Adjust scaling for smaller screens if necessary */
  }
}

.cs_obtenir_tarif_btn:hover {
  background-color: white;
  color: #cc0000 !important;
  /* Darker orange on hover */
}



.cs_hero.cs_style_1 {
  @media (max-width: 991px) {
    .cs_hero_img {
      max-width: none;
      width: 90vw;

      img {
        width: 100%;
        height: auto;

      }
    }

    .cs_hero_text {
      text-align: center;

      .cs_hero_title {
        font-size: 42px;
        margin-bottom: 20px;
      }

      .cs_hero_subtitle {
        font-size: 18px;
        margin-bottom: 30px;
      }

      .cs_hero_savings {
        font-size: 20px;
        margin-bottom: 25px;
      }
    }
  }
}